import React from 'react';
import help from '../../../src/assets/img/help.png'
const SupportCenter = () => {
  const handleSubmitRequest = () => {
    // Construct the mailto link
    const mailtoLink = "mailto:rallyTrax2020@gmail.com?subject=Support%20Request&body=Please%20describe%20your%20issue%20here.";
    // Open the default mail application
    window.location.href = mailtoLink;
  };

  return (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <h1 style={styles.title}>Support Center</h1>
        <p style={styles.subtitle}>
          Need help? Submit a support request and we'll get back to you ASAP.
        </p>
        <button style={styles.button} onClick={handleSubmitRequest}>
          Submit a Request
        </button>
      </div>
      <div style={styles.imageContainer}>
        <img src={help} alt="Support" style={styles.image} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#5ba2f6',
    padding: '0 20px',
  },
  textContainer: {
    maxWidth: '500px',
  },
  title: {
    fontSize: '48px',
    color: '#fff',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '18px',
    color: '#fff',
    margin: '20px 0',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#333',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  image: {
    maxWidth: '50%',
    height: 'auto',
  },
};

export default SupportCenter;
