import React from 'react';
import { connect } from 'dva';
import { Empty, Card, Typography, Alert, Input, Button, Table, Radio, Divider, Switch, Row, Col, Avatar, Pagination, Tabs, Modal, Popconfirm } from 'antd';
import { UserOutlined, LockOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import jwt_decode from "jwt-decode";
import fetch from 'dva/fetch';
import AddEdit from './action/addEdit';
import moment from 'moment';

const { Search } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;
//const baseUrl = process.env.REACT_APP_ApiUrl

class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: 0, Addcount: 0, limit: 25, current: 1, searchText: '', loader: false, detail: '', addModel: false, listData: [], data: [], pagination: { current: 1, pageSize: 10 }, loading: false, sortBy: 'asc', inactive: false }
    setTimeout(() => document.title = 'Tansaction List', 100);
  }
  componentDidMount() {
    this.ListFun();
    // this.props.dispatch({ type: 'tag/clearAction' });
  }

  ListFun = () => {
    const user = jwt_decode(localStorage.getItem('token'));
if (user.role === "ADMIN") {
  let search = 'page=' + (this.state.current - 1) + "&limit=" + this.state.limit + "&inactive=" + this.state.inactive + "&searchText=" + this.state.searchText + "&sortBy=" + this.state.sortBy;
  localStorage.setItem('newsSearch', JSON.stringify(this.state));
  let searchval = { page: this.state.current - 1, limit: this.state.limit, inactive: this.state.inactive, searchText: this.state.searchText, sortBy: this.state.sortBy }
 console.log("test",this.props.dispatch({ type: 'txn/txnList', payload: searchval, })) ;
} 

}
ShowSizeChange = (current, size) => this.setState({ limit: size }, () => this.ListFun());
switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
ChangeOrder = (val) => this.setState({ sortBy: this.state.sortBy === 'asc' ? 'desc' : 'asc' }, () => this.ListFun());
paginationFun = (val) => this.setState({ current: val.current }, () => this.ListFun());

  searchVal = (val) => {
    this.state.searchText = val
    const resultAutos = this.props.txn.list.data.filter((auto) => auto.business_username.toLowerCase().includes(val.toLowerCase()) || auto.user_name.toLowerCase().includes(val.toLowerCase()))

    this.setState({ listData: resultAutos })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot) {
			this.ListFun()
		}
  }
  
  createCat = (val) => {
		console.log(val)
		if (val) { this.ListFun() }
		this.setState({ detail: '', addModel: false })
  }
  
  deleteCat = id => {
		this.props.dispatch({ type: 'tag/tagDelete', payload: { _id: id }, });
  }
 
 
  render() {
    const {loading, addModel, detail, searchText} = this.state;
    console.log("this.state",this.state)
    const { txn } = this.props;
    const total = 0; 
    const totalActive = 0;
    if (this.state.searchText == '') {
      this.state.listData = txn.list ? txn.list.data : [];
    }



    const productsAvilabilityColumns = [

		{
			title: <strong>S.N.</strong>,
			dataIndex: 'index',
			render:(val,data,rowIndex) => {
				console.log("rowIndex",rowIndex)
			  return (<span className="text-info" >{rowIndex +1 }</span>)
			}
		  },
      {
        title: <strong>Booking Number</strong>,
        dataIndex: 'booking_number',
        render:(val,data) => {
          return (data.booking_number? <span className="text-info" >{data.booking_number}</span>:'-')
        }
      },
	  {
        title: <strong>Event Name</strong>,
        dataIndex: 'event_name',
        render:(val,data) => {
          return (data.event_name? <span className="text-info" >{data.event_name}</span>:'-')
        }
      },
	  {
        title: <strong>User Name</strong>,
        dataIndex: 'amount',
        render:(val,data) => {
          return (data.user_name? <span className="text-info" >{data.user_name}</span>:'-')
        }
      },
     {
        title: <strong>Business User</strong>,
        dataIndex: 'business_username',
        render:(val,data) => {
          return (data.business_username? <span className="text-info" >{data.business_username}</span>:'-')
        }
      },
     {
        title: <strong>Amount</strong>,
        dataIndex: 'amount',
        render:(val,data) => {
          return (data.amount? <span className="text-info" >{'$'}{data.amount}</span>:'-')
        }
      },
     {
        title: <strong>Status</strong>,
        dataIndex: 'payment_status',
        render:(val,data) => {
          return (data.payment_status? <span className="text-info" >{data.payment_status}</span>:'-')
        }
      },
     {
        title: <strong>Transaction Date</strong>,
        dataIndex: 'created_at',
        render:(val,data) => {
          return (data.created_at? <span className="text-info" >{moment(data.created_at).format('ll')}</span>:'-')
        }
      },
     
    ];

    return (
      <>
      <Card>
        <Row style={{ marginBottom: "0.625rem" }} className="TopActionBar" gutter={[16, 0]} justify="space-between" align="middle">
          <Col>
            <Search placeholder="Search..." loading={this.props.submitting} onChange={(e) => this.searchVal(e.target.value)} value={searchText} />
          </Col>
          
        </Row>
        <Table
          columns={productsAvilabilityColumns}
          rowKey={record => record._id}
          dataSource={this.state.listData}
          onChange={this.paginationFun}
          onRow={(record, rowIndex) => {
            // return {
            //   onClick: event => this.setState({ addModel: true, detail: record })
            // };
          }}
          pagination={{
								position: ['bottomLeft'],
								showTotal: (total, range) => <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>, showSizeChanger: true,
								responsive: true,
								onShowSizeChange: (current, size) => this.ShowSizeChange(current, size),
								pageSizeOptions: ['25', '50', '100', '250', '500'],
							}}
        />
      </Card>
      {/* <AddEdit visible={addModel} returnData={this.createCat} closeModel={() => this.setState({ addModel: false, detail: '' })} detail={detail} /> */}
      </>

    );
  }
};


export default connect(({ txn, loading }) => ({
  txn, 
  loading
}))(TransactionList);
