import { txnList  } from '../services/api'
import { message } from 'antd';

export default {
  namespace: 'txn',

  state: {
    add: { count: 0 },
    edit: { count: 0 },
    fileUp: { count: 0 },
    del: { count: 0 }
  },

  subscriptions: {
    setup({ dispatch, history }) {
    },
  },

  effects: {
    *txnList({ payload }, { call, put }) {
      console.warn("coming here")
      const response = yield call(txnList, payload);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'list', ...response });
    },
  
    
  
  
  },

  reducers: {
    list(state, action) {
      return { ...state, list: action };
    },
   
  },
};