 import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Logo from '../../assets/img/icon_cb.jpg';

const Home = () => {
  return (
    <div className="welcome-page-container basicpage">
      <div className="welcome-logo-container">
        <img 
          src={Logo} 
          alt="Logo" 
          className="welcome-logo"
        />
      </div>
      <div className="welcome-description-container">
        <h2>Welcome to Rally Trax</h2>
        <p>
        Rally Trax is a platform where the rally participants can find the poker run events with their details for example event name, location, and entry ticket price.
        </p>
      </div>
      <Link 
        to="/login" 
        className="welcome-login-button"
      >
        Login
      </Link>
    </div>
  );
};

export default Home;
