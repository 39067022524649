import React, { useEffect, useState } from "react";
import "./DeleteForm.css"; // Import your CSS file for styling
// import { useSelector, useDispatch } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import { toast } from "react-toastify";
// import { BASE_URL } from "../../../../Config/Config";
// import { createDeleteRequestAction } from "../../../../Redux/Actions/DeleteAction";
import { deleteAccount } from "../../services/api";
import Logo from '../../assets/img/icon_cb.jpg';

const DeleteForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
  });
//   const dispatch: any = useDispatch();

//   const formStateData = useSelector((state: any) => state?.contactUsState);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation example: Check if required fields are filled
    if (formData.name.trim() === "" || formData.email.trim() === "") {
    //   toast.error("Please fill in all required fields.");
      return;
    }
    // Handle form submission logic here, e.g., sending data to server

    deleteAccount(formData)
    .then(data=>{
      if(data){
        alert(data.message)
      }
    }).catch(err=>{
      console.log('err',err)
    })

    // Reset form fields if needed
    setFormData({
      name: "",
      email: "",
      description: "",
    });
  };

// useEffect(()=>{
// if(formStateData.status){
//   setFormData({
//     name: "",
//     email: "",
//     description: "",
//   });
// }
// },[formStateData])

  return (
    <>
      <div className="contact-form-wrap">
        <div className="contact-container">
          <div className="contact">
            <div className="contact-form-row">
              <div className="contact-form-col-left">
                <div className="contact-info basicpage">
                  <img
                    src={Logo}
                    alt="image"
                    className="welcome-logo"
                  />
                  <h2 className="text-white">Delete Account Request</h2>
                  <h4 className="text-white">
                    We are sad to see you going!
                  </h4>
                </div>
              </div>
              <div className="contact-form">
                <form className="mw-100" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="control-label" htmlFor="name">
                      Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter First Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="control-label" htmlFor="email">
                      Email:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="description">
                      Description:
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      name="description"
                      id="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Enter your description"
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-default">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
    </>
  );
};

export default DeleteForm;
