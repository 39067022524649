import React from "react";
import Apploader from "./../../components/loader/loader";
import { connect } from "dva";
import {
  Card,
  Typography,
  Input,
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  message,
} from "antd";
import {
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { getAllDeletedUser, updateDeleteStatus } from "../../services/api";

const { Search } = Input;
const { Text } = Typography;

class DeleteRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 25,
      current: 1,
      searchText: "",
      loader: false,
      listData: [],
    };
    setTimeout(() => (document.title = "Delete Account Requests"), 100);
  }

  componentDidMount() {
    this.fetchDeleteRequests();
  }

  fetchDeleteRequests = async () => {
    this.setState({ loader: true });
    const { limit, current } = this.state;
    const requestBody = { limit, page: current - 1 };

    try {
      const response = await getAllDeletedUser(requestBody);
      this.setState({ listData: response.data, loader: false });
    } catch (error) {
      message.error("Failed to fetch delete requests.");
      this.setState({ loader: false });
    }
  };

  handleApprove = async (id) => {
    try {
      const requestBody = { _id: id, status: "ACCEPT" };
      const response = await updateDeleteStatus(requestBody);
      message.success(response.message);
      this.fetchDeleteRequests();
    } catch (error) {
      message.error("Failed to approve request.");
    }
  };

  handleReject = async (id) => {
    try {
      const requestBody = { _id: id, status: "REJECT" };
      const response = await updateDeleteStatus(requestBody);
      message.success(response.message);
      this.fetchDeleteRequests();
    } catch (error) {
      message.error("Failed to reject request.");
    }
  };

  searchVal = (val) => {
    this.setState({ searchText: val, current: 1 }, this.fetchDeleteRequests);
  };

  render() {
    const { searchText, listData, loader } = this.state;

    const columns = [
      { title: <strong>Name</strong>, dataIndex: "name" },
      { title: <strong>Email</strong>, dataIndex: "email" },
      { title: <strong>Description</strong>, dataIndex: "description" },
      {
        title: <strong>Actions</strong>,
        render: (text, record) => {
          if (record.deleted) {
            return <Text type="danger">Account Deleted</Text>;
          }
          return (
            <div>
              <Popconfirm
                title="Are you sure you want to approve this request?"
                onConfirm={() => this.handleApprove(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" icon={<CheckOutlined />} />
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to reject this request?"
                onConfirm={() => this.handleReject(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" icon={<CloseOutlined />} />
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Apploader show={loader} />
        <Row
          className="TopActionBar"
          gutter={[16, 0]}
          justify="space-between"
          align="middle"
        >
          <Col>
            <Search
              placeholder="Search..."
              onChange={(e) => this.searchVal(e.target.value)}
              value={searchText}
            />
          </Col>
        </Row>

        <div className="innerContainer">
          <Card
            style={{ marginTop: "0" }}
            bodyStyle={{ padding: "0 15px 15px" }}
          >
            <Table
              columns={columns}
              dataSource={listData}
              rowKey={(record) => record._id}
              pagination={{
                position: ["bottomLeft"],
                showTotal: (total, range) => (
                  <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
                ),
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "100"],
                onChange: (page, pageSize) =>
                  this.setState(
                    { current: page, limit: pageSize },
                    this.fetchDeleteRequests
                  ),
              }}
            />
          </Card>
        </div>
      </div>
    );
  }
}

export default DeleteRequest;
// export default connect(({ DeleteRequest, loading }) => ({
//     DeleteRequest, loading
// }))(DeleteRequest);
