import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Row, Col, Empty, Modal, Card, Typography, Alert, Form, Input, Checkbox, Button, Space, Upload, Dropdown, Menu, Select, notification, Transfer, DatePicker, Avatar, message, InputNumber } from 'antd';
import { LeftOutlined, LoadingOutlined, EditOutlined, CloseOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import UploadImages from '../../../components/sharing/upload-images'
import CropImage from '../../../components/sharing/crop-image'
import TextEditor from '../../../components/sharing/text-editor'
import moment from 'moment';
import { connect } from 'dva'; 
import styles from './style.less'; 
import { getSubCatbyCategory } from '../../../services/api'
import MultiImageInput from 'react-multiple-image-input';
import HTMLDecoderEncoder from 'html-encoder-decoder';

const { Text } = Typography;
const { TextArea } = Input;
const timestemp = (new Date()).getTime();
const { RangePicker } = DatePicker;
const formItemLayout = { labelCol: { xs: { span: 24, }, sm: { span: 24, }, } };
const baseUrl = process.env.REACT_APP_ApiUrl
const AddEditBroadcast = props => {
	const [form] = Form.useForm();
	const { dispatch } = props;
	const [Inquiry, setInquiry] = useState('');
	const [PageId, setPageId] = useState('');
	const [count, setCount] = useState(0)

	useEffect(() => {
		let unmounted = false;
		window.scroll(0, 0);
		if (props.match.params.id) {
			DetailFun(props.match.params.id)
		} else {
			form.resetFields();
		}
		return () => { unmounted = true; }
	}, [dispatch])
	
	const DetailFun = (id) => {
		props.dispatch({ type: 'broadcast/broadcastDetail', payload: id });
	}

	useEffect(() => {
		let unmounted = false;

		if(props.broadcast.add){
			dispatch({ type: 'broadcast/clearAction'});
			props.history.push('/broadcasts');
		}
		
		if(props.broadcast.edit){
			dispatch({ type: 'broadcast/clearAction'});
			props.history.push('/broadcasts');
		}
		
		if(props.broadcast && props.broadcast.detail && props.broadcast.detail.status){
			let data = props.broadcast.detail.data[0];
			setPageId(data._id)
			// setInquiry(HTMLDecoderEncoder.decode(data.html));
			form.setFieldsValue({
				['subject']: data.subject, 
				['message']: data.message, 
				['isActive']: data.isActive, 

			})
		}

		return () => { unmounted = true; }
	}, [props.broadcast])

	const cancelFun = () => {
		form.resetFields();
		props.history.push('/broadcasts');
	}

	const onFinish = val => {
		// val.html = HTMLDecoderEncoder.encode(Inquiry);
		// val = convertUndefinedObjectKeysToEmptyString(val);

		if (props.match.params.id) {
			val._id = PageId;

			dispatch({ type: 'broadcast/EditBroadcast', payload: val });
		}else {
			dispatch({ type: 'broadcast/AddBroadcast', payload: val });
		}
	}

	const convertUndefinedObjectKeysToEmptyString = (object) => {
		var output = {};
		for(let i in object) {
			if(!object[i]) {
				output[i] = "";
			} else {
				output[i] = object[i];
			}	
		}
		return output;
	}

	return (
		<Card title={<span><LeftOutlined onClick={() => props.history.push('/broadcasts')} /> 
			{ props.detail ? 'Edit Broadcast' : 'Add Broadcast'}</span>} style={{ marginTop: "0" }}>

			<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">
			
				<Row gutter={15}>
					<Col sm={24} md={12}>
						<Form.Item name="subject" label="Subject" rules={[{ required: true, message: 'Field required!' },]}  >
							<Input placeholder="Subject" />
						</Form.Item>
					</Col>
				</Row>


				{/* <Row gutter={15}>
					<Col sm={24} md={24}>
						<Form.Item name="html" label="html" rules={[{ required: false, message: 'This field is required!' }]} >
							<TextEditor returnVal={val => setInquiry(val)} data={Inquiry}/>
						</Form.Item>
					</Col>
				</Row> */}

				<Row gutter={15}>
				<Col sm={24} md={24}>
				<Form.Item name="message" label="Message" rules={[{ required: true, message: 'This field is required!' }]} >
                        	<TextArea placeholder="Message"  rows={6}/>
						</Form.Item>
						</Col>
				</Row>

				<Form.Item  name="isActive" valuePropName="checked" >
                  <Checkbox>isActive</Checkbox>
              </Form.Item>

				<Form.Item className="mb-0">
					<Button onClick={cancelFun}>Cancel</Button>&nbsp;&nbsp;
					<Button type="primary" className="btn-w25 btn-primary-light" onClick={() => form.submit()}>Save</Button>
				</Form.Item>

	

		

				
			</Form>

		</Card>
	)
};

export default connect(({ broadcast, global, loading }) => ({
	broadcast: broadcast,
	global: global
}))(AddEditBroadcast);