import React, { useState, useEffect } from 'react';
import './Static.css';
import Logo from '../../assets/img/icon_cb.jpg';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_ApiUrl;

const StaticPage = () => {
  const [data, setData] = useState({});
  const slug = window.location.hash.split('/').pop();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/get-pages?slug=${slug}`);
        setData(response.data);
        console.log('Data',data)
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };

    if (slug) {
      fetchData();
    }
  }, [slug]);

  return (
    <div className="welcome-page-container-static">
      <div className="welcome-logo-container">
        <img src={Logo} alt="Logo" className="welcome-logo" />
      </div>
      <div className="welcome-description-container-static">
        <h2 className='h2-title'>{data?.data?.length > 0 ? data?.data[0]?.title : ""}</h2>
        <div className="container">
          <pre className='cms-txt-ui' dangerouslySetInnerHTML={{ __html:data?.data?.length > 0 ?  data?.data[0]?.description : '' }} />
        </div>
      </div>
    </div>
  );
};

export default StaticPage;
